import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Sidebar } from '@opengov/component-library/capital';
import { FigmaEmbed, PatternExample, UsageBlock } from '../../../components';
import LayoutComponents from '../../../components/capital/Layouts/LayoutComponents';
export const _frontmatter = {
  "title": "Sidebar",
  "componentId": "sidebar",
  "description": "Sidebars display a collapsible section of secondary content secondary to the right or left of the main content of the page.",
  "activeTab": "components"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = LayoutComponents;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p><strong parentName="p">{`Documentation in progress---------------------`}</strong></p>
    <p>{`A Sidebar is a collapsible block of content displayed to the right or the left of the main content area.`}</p>
    <p>{`By default it is built with an `}<inlineCode parentName="p">{`<aside>`}</inlineCode>{` HTML element.`}</p>
    <p>{`The sidebar is used to display additional content or functionality that is considered secondary in nature to the main content, and therefore would be okay for the user to hide in favor of more screen room for the main content.`}</p>
    <h2>{`Configuration`}</h2>
    <PatternExample example={<Sidebar title="Sidebar Title" mdxType="Sidebar">
  Example Sidebar Content
    </Sidebar>} title="..." mdxType="PatternExample" />
    <h2>{`Usage`}</h2>
    <UsageBlock mdxType="UsageBlock">
  <UsageBlock.Correct>
    <p>...</p>
  </UsageBlock.Correct>
  <UsageBlock.Incorrect>
    <p>...</p>
  </UsageBlock.Incorrect>
    </UsageBlock>
    <h2>{`Layout`}</h2>
    <PatternExample example={"..."} title="..." description={"Description about layout structure"} mdxType="PatternExample" />
    <h2>{`Content`}</h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      